import React, { useState, useEffect } from "react";
import { database } from "../../firebase";
import { getFirestore, collection, query as queryFirestore, where, orderBy, getDocs } from 'firebase/firestore';
import { onValue, ref, orderByChild, startAfter, onChildAdded, endBefore, query, equalTo } from "firebase/database";
import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Snackbar, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { utils, writeFile } from 'xlsx';
import moment from "moment";
import MuiAlert from '@mui/material/Alert';
import { headingsTable, tipoOt } from '../../utils/default'
import { getCuadrillasOnce } from "../../services/cuadrillasService";
import { getPricesOnce } from "../../services/priceService";
const firestore = getFirestore();

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function DownloadExcel({ open, onCloseModal }) {
    const [alertValidationDate, setAlertValidationDate] = useState('')
    const [snackbarInfo, setSnackbarInfo] = useState({ open: false, text: '', type: 'neutral' })
    const [prices, setPrices] = useState('')
    const [cuadrillas, setCuadrillas] = useState([])
    const [idCuadrilla, setIdCuadrilla] = useState(-1);
    const [state, setState] = useState({
        dateTo: null,
        dateFrom: null,
    });
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        // onValue(ref(database, '/precios_new/'), (snapshot) => {
        //     setPrices(Object.values(snapshot.val()));
        // }, {
        //     onlyOnce: true
        // });
        // onValue(ref(database, '/cuadrillas/'), (snapshot) => {
        //     setCuadrillas(Object.values(snapshot.val()))
        // }, {
        //     onlyOnce: true
        // });
        getPricesOnce(setPrices)
        getCuadrillasOnce(setCuadrillas)
    }, [])

    const handleCloseSanckbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarInfo({ text: '', type: 'info', open: false });
    };

    const onChangeDateTo = (value) => {
        const d = new Date(value);
        const isBeforeDate = moment(d).isBefore(moment(new Date()));
        if (isBeforeDate) {
            setState(prevState => ({ ...prevState, dateTo: value }));
            setAlertValidationDate('')
        } else {
            setAlertValidationDate('La fecha debe ser menor o igual a la actual')
        }
    };

    const onChangeDateFrom = (value) => {
        const d = new Date(value);
        const isBeforeDate = moment(d).isBefore(moment(new Date()));
        if (isBeforeDate) {
            setState(prevState => ({ ...prevState, dateFrom: value }));
            setAlertValidationDate('')
        } else {
            setAlertValidationDate('La fecha debe ser menor o igual a la actual')
        }
    };

    const filterValuesServices = (value, id, type) => {
        const newFilter = value?.filter(valor => valor.id === id)
        const valueExist = newFilter?.length > 0
        let valueToReturn = type === 'bool' ? 'NO' : "0";
        if (valueExist) {
            valueToReturn = newFilter[0]?.value === true ? 'SI' : newFilter[0]?.value
        }
        return valueToReturn
    }

    const filterValuesMaterials = (value, id) => {
        const newFilter = value?.filter(valor => valor.id === id);
        const valueExist = newFilter?.length > 0;
        let valueToReturn = valueExist ? newFilter[0]?.value : '';
        return valueToReturn
    }

    const filterControles = (controles) => {
        let sumControl = 0
        controles && controles?.forEach(control => (
            sumControl += control.value !== '' ? parseInt(control.value, 10) : 0
        ))
        return sumControl
    }

    const priceValueServices = (value, id, tipoCliente, horaTrabajo, tipoDia) => {
        const newFilter = value?.filter(valor => valor.id === id)
        const valueExist = newFilter?.length > 0
        const typeValue = valueExist && typeof newFilter[0]?.value === 'boolean';
        let priceService = '';

        if (valueExist) {
            // if (tipoCliente === 'HOME') {
            const priceHogar = tipoDia === 'Semana' ?
                parseInt(prices[id]?.hogar, 10) :
                parseInt(prices[id]?.feriado_domingo_hogar, 10);
            priceService = typeValue ? priceHogar : priceHogar * parseInt(newFilter[0]?.value, 10);
            // } else {
            //     const priceCorpo = tipoDia === 'Semana' ?
            //         parseInt(prices[id]?.corporativo, 10) :
            //         parseInt(prices[id]?.feriado_domingo_corporativo, 10);
            //     priceService = typeValue ? priceCorpo : priceCorpo * parseInt(newFilter[0]?.value, 10);
            // }
        }
        return priceService
    }

    const formatData = (orders) => {
        const newOrderFormat = []
        orders.map((order, index) => {
            const newOrder = {
                item: order?.id,
                formulario: order?.nroFormulario || '',
                departamento: order?.departamento || '',
                contrato: order?.contrato || '',
                ot: order?.nroOt || '',
                cliente: order?.cliente || '',
                ciudad: order?.ciudad || '',
                latitud: order?.latitud || '',
                logitud: order?.longitud || '',
                tipoCliente: order?.tipoCliente || '',
                horaTrabajo: order?.horasTrabajo || '',
                dia: moment(order?.date, "YYYYMMDD").format("DD"),
                mes: moment(order?.date, "YYYYMMDD").format("MM"),
                año: moment(order?.date, "YYYYMMDD").format("YYYY"),
                fecha: moment(order?.date, "YYYYMMDD").format("DD/MM/YYYY") || '',
                tipoDia: order?.tipoDia || '',
                tipoOt: order?.tipoOt || '',
                instalacionBasica1p: filterValuesServices(order?.services, 0, 'bool'),
                instalacionFlow: filterValuesServices(order?.services, 1, 'bool'),
                instalacionBasica2p: filterValuesServices(order?.services, 2, 'bool'),
                mediaJornadatrabajoFTTHWireless: filterValuesServices(order?.services, 3, 'bool'),
                jornalCompletatrabajoFTTHWireless: filterValuesServices(order?.services, 4, 'bool'),
                soporteFTTX: filterValuesServices(order?.services, 6, 'bool'),
                soporteCorteReinstalaciónFTTX: filterValuesServices(order?.services, 7, 'bool'),
                relevamiento: filterValuesServices(order?.services, 5, 'bool'),
                SoporteGarantía: '',
                cablePreconectorizado50M: filterValuesMaterials(order?.materials, 0),
                cablePreconectorizado100M: filterValuesMaterials(order?.materials, 1),
                cablePreconectorizado150M: filterValuesMaterials(order?.materials, 2),
                cablePreconectorizado220M: filterValuesMaterials(order?.materials, 3),
                cablePreconectorizado300M: filterValuesMaterials(order?.materials, 4),
                cableDropMTSExterno: filterValuesMaterials(order?.materials, 6),
                retenedorFOyGancho: filterValuesMaterials(order?.materials, 7),
                cintaMetalicaAbrazadera: filterValuesMaterials(order?.materials, 8),
                pitonTarugo: filterValuesMaterials(order?.materials, 9),
                ojalaceroGalvanizado: filterValuesMaterials(order?.materials, 10),
                canaletas20x10: filterValuesMaterials(order?.materials, 11),
                cintillo: filterValuesMaterials(order?.materials, 12),
                tubitosPasaPared: filterValuesMaterials(order?.materials, 25),
                fichasRJ45: filterValuesMaterials(order?.materials, 13),
                // fichasRJ45Cat5e: filterValuesMaterials(order?.materials, 14),
                cableUTPCat5e: filterValuesMaterials(order?.materials, 15),
                conectorSC: filterValuesMaterials(order?.materials, 16),
                hebillaAbrazaderaFijacion: filterValuesMaterials(order?.materials, 17),
                grampas: filterValuesMaterials(order?.materials, 18),
                // tarugoTopeTornillo6MM: filterValuesMaterials(order?.materials, 19),
                tarugoTopeTornilloHueco6MM: filterValuesMaterials(order?.materials, 20),
                // tornilloFix5MMCorto: filterValuesMaterials(order?.materials, 21),
                tornilloFix5MMLargo: filterValuesMaterials(order?.materials, 22),
                nomenclaturaNap: '',
                // herrajeSoportePared: filterValuesMaterials(order?.materials, 23),
                serialONT: order?.serialOnt?.length > 0 ? order?.serialOnt[0] : '',
                cantidadDecos: order?.serialesFlow?.length,
                serialesFlow: order?.serialesFlow?.length > 0 ? order?.serialesFlow[0] : '',
                serialesFlow1: order?.serialesFlow?.length > 1 ? order?.serialesFlow[1] : '',
                serialesFlow2: order?.serialesFlow?.length > 2 ? order?.serialesFlow[2] : '',
                serialesFlow3: order?.serialesFlow?.length > 3 ? order?.serialesFlow[3] : '',
                serialesFlow4: order?.serialesFlow?.length > 4 ? order?.serialesFlow[4] : '',
                serialesExt1: order?.serialesExt?.length > 0 ? order?.serialesExt[0] : '',
                serialesExt2: order?.serialesExt?.length > 1 ? order?.serialesExt[1] : '',
                serialesExt3: order?.serialesExt?.length > 2 ? order?.serialesExt[2] : '',
                serialesExt4: order?.serialesExt?.length > 3 ? order?.serialesExt[3] : '',
                serialEquipoConfigurado: order?.serialEquipoConfigurado?.length > 0 ? order?.serialEquipoConfigurado[0] : '',
                serialEquipoConfigurado2: order?.serialEquipoConfigurado?.length > 1 ? order?.serialEquipoConfigurado[1] : '',
                serialEquipoConfigurado3: order?.serialEquipoConfigurado?.length > 2 ? order?.serialEquipoConfigurado[2] : '',
                // controlDecoSagem: filterValuesMaterials(order?.controles, 0),
                // controlDecoSagemReac: filterValuesMaterials(order?.controles, 1),
                controlDecoAndroid: filterControles(order?.controles), //Revisar cual es el flow
                // controlDecoAndroidReac: filterValuesMaterials(order?.controles, 3),
                ontRetirado: order?.ontRetirado?.length > 0 ? order?.ontRetirado[0] : '',
                decoRETIRADO: order?.decoRetirado?.length > 0 ? order?.decoRetirado[0] : '',
                extRETIRADO: order?.extRetirado?.length > 0 ? order?.extRetirado[0] : '',
                rosetaOptica: filterValuesMaterials(order?.materials, 26),
                nroCuadrilla: order?.codCuadrilla || '',
                adaptadorMecanico: filterValuesMaterials(order?.materials, 24),
                cuadrilla: order?.cuadrilla || '',
                // identificadorDrop0: filterValuesMaterials(order?.materials, 27),
                // identificadorDrop1: filterValuesMaterials(order?.materials, 28),
                // identificadorDrop2: filterValuesMaterials(order?.materials, 29),
                // identificadorDrop3: filterValuesMaterials(order?.materials, 30),
                // identificadorDrop4: filterValuesMaterials(order?.materials, 31),
                // identificadorDrop5: filterValuesMaterials(order?.materials, 32),
                // identificadorDrop6: filterValuesMaterials(order?.materials, 33),
                // identificadorDrop7: filterValuesMaterials(order?.materials, 34),
                // identificadorDrop8: filterValuesMaterials(order?.materials, 35),
                // identificadorDrop9: filterValuesMaterials(order?.materials, 36),
                // nap: order?.nap || '',
                // tipoOt: order?.tipoOt || '',
                usuario: order?.user || '',
                priceinstalacionBasica1p: priceValueServices(order?.services, 0, order?.tipoCliente, order?.horasTrabajo, order?.tipoDia),
                priceinstalacionFlow: priceValueServices(order?.services, 1, order?.tipoCliente, order?.horasTrabajo, order?.tipoDia),
                priceinstalacionBasica2p: priceValueServices(order?.services, 2, order?.tipoCliente, order?.horasTrabajo, order?.tipoDia),
                pricemediaJornadatrabajoFTTHWireless: priceValueServices(order?.services, 3, order?.tipoCliente, order?.horasTrabajo, order?.tipoDia),
                pricejornalCompletatrabajoFTTHWireless: priceValueServices(order?.services, 4, order?.tipoCliente, order?.horasTrabajo, order?.tipoDia),
                pricesoporteFTTH: priceValueServices(order?.services, 6, order?.tipoCliente, order?.horasTrabajo, order?.tipoDia),
                pricereinstalacionFtth: priceValueServices(order?.services, 7, order?.tipoCliente, order?.horasTrabajo, order?.tipoDia),
                pricerelevamiento: priceValueServices(order?.services, 5, order?.tipoCliente, order?.horasTrabajo, order?.tipoDia),
                priceSoporteGarantía: '',
                // pricedocumentaciónCliente: {v: priceValueServices(order?.services, 16, order?.tipoCliente, order?.horasTrabajo, order?.tipoDia), s: { numFmt: "0.00%" }},
                total: { f: `SUM(BS${index + 2}:CA${index + 2})` },
                comment: order?.comments,
                confectionData: order?.confectionData,
            }
            newOrderFormat.push(newOrder)
            return (
                newOrderFormat
            )
        })
        return newOrderFormat
    }

    const handleExport = (displayTable) => {
        const dataDisplay = idCuadrilla !== -1 ? displayTable?.filter(order => order?.idCuadrilla === idCuadrilla) : displayTable;
        if (dataDisplay.length > 0) {
            const dateNow = moment(new Date()).format("YYYYMMDD")
            const wb = utils.book_new();
            const ws = utils.json_to_sheet([]);
            const dataToExport = formatData(dataDisplay)
            utils.sheet_add_aoa(ws, headingsTable);
            utils.sheet_add_json(ws, dataToExport, { origin: 'A2', skipHeader: true });
            utils.book_append_sheet(wb, ws, 'Report');
            writeFile(wb, `ordenes-${dateNow}.xlsx`);
        } else {
            setSnackbarInfo({ open: true, text: 'No hay datos para descargar con estos parámetros', type: 'error' })
        }
    }

    const onClickAccept = async () => {
        const ordenesCollection = collection(firestore, 'ordenes');

        const dateFrom = moment(state.dateFrom, "DD/MM/YYYY").format("YYYYMMDD");
        const dateTo = moment(state.dateTo, "DD/MM/YYYY").format("YYYYMMDD");
        const topUserPostsRef = queryFirestore(
            ordenesCollection, orderBy('date', 'asc'),
            where('date', '>=', dateFrom),
            where('date', '<=', dateTo)
        );
        setLoading(true)
        // Ejecutar la consulta
        const querySnapshot = await getDocs(topUserPostsRef);

        if (!querySnapshot.empty) {
            const data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            handleExport(data);
        } else {
            handleExport([]);
        }
        // onValue(topUserPostsRef, (snapshot) => {
        //     ordersArray = snapshot.val() ? Object.values(snapshot.val()) : [];
        //     handleExport(ordersArray)
        // }, {
        //     onlyOnce: true
        // });
        setTimeout(() => {
            setLoading(false)
        }, 6000);
        // setOpenModal(false)
    }

    const onChangeSelect = (event, cuadrilla) => {
        const value = cuadrilla.id;
        setIdCuadrilla(value);
    }

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Ingrese fechas desde y hasta para descargar la planilla"}
                </DialogTitle>
                <DialogContent className="dialog-dates" sx={{ paddingTop: '24px' }}>
                    <LocalizationProvider dateAdapter={AdapterMoment} sx={{ marginRight: '10px' }}>
                        <DatePicker
                            className="date-selector"
                            label="Fecha Desde"
                            inputFormat="DD/MM/YYYY"
                            value={state.dateFrom}
                            onChange={onChangeDateFrom}
                            renderInput={(params) => <TextField {...params} />}
                            sx={{ marginRight: '10px' }}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            className="date-selector"
                            label="Fecha Hasta"
                            inputFormat="DD/MM/YYYY"
                            value={state.dateTo}
                            onChange={onChangeDateTo}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                    <FormControl sx={{ width: '83%', margin: '10px 0' }}>
                        <Autocomplete
                            options={cuadrillas}
                            id="disable-clearable"
                            disableClearable
                            onChange={(e, cuadrilla) => onChangeSelect(e, cuadrilla)}
                            getOptionLabel={(option) => option.numero}
                            fullWidth
                            renderInput={(params) => (
                                <TextField {...params} label="Cuadrilla" placeholder="Cuadrilla" />
                            )}
                        />
                    </FormControl>
                    {alertValidationDate !== '' && <MuiAlert severity="error">{alertValidationDate}</MuiAlert>}
                </DialogContent>
                <DialogActions>
                    {loading && <CircularProgress />}
                    <Button onClick={onCloseModal}>Cerrar</Button>
                    <Button
                        onClick={onClickAccept}
                        disabled={state.dateFrom === null || state.dateTo === null}
                    >
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbarInfo.open} autoHideDuration={2000} onClose={handleCloseSanckbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleCloseSanckbar} severity={snackbarInfo.type} sx={{ width: '100%' }}>
                    {snackbarInfo.text}
                </Alert>
            </Snackbar>
        </>
    );
}
